import React, { useState, useEffect } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";

function Country() {

    /*
        useEffect(() => {
    
            const changeCountryAfterDelay = setTimeout(() => {
                setCountry('NewZealand');
            }, 6000);
            return () => clearTimeout(changeCountryAfterDelay);
        }, []);
    */

    const [country, setCountry] = useState("Australia");
    const [progress, setProgress] = useState(0);



    useEffect(() => {
        let timer = null;
        const updateProgress = () => {
            timer = setInterval(() => {
                setProgress((prevProgress) => prevProgress + (100 / 6));
            }, 1000);
        };
        updateProgress();
        const changeCountryAfterDelay = setTimeout(() => {
            setCountry('NewZealand');
            clearInterval(timer);
        }, 6000);
        return () => {
            clearInterval(timer);
            clearTimeout(changeCountryAfterDelay);
        };
    }, []);











    function Australia() {

        const items = [
            {
                title: "Australia Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Australia Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Australia Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Australia Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Australia Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Australia Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
        ];
        return (
            <>

                <div className="flex flex-wrap justify-center pb-10">
                    {items.map((item, index) => (
                        <div key={index} className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-4">
                            <a href={item.link}>
                                <img className="rounded-t-lg" src={item.imageUrl} alt="" />
                            </a>
                            <div className="p-5">
                                <a href="#">
                                    <h5 className="mb-2 text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>


            </>
        )
    }





    function Newzealand() {

        const items = [
            {
                title: "Newzealand Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Newzealand Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Newzealand Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Newzealand Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Newzealand Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
            {
                title: "Newzealand Wide",
                imageUrl: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/melbourne-2ff9b2f3-8a5d-4c3d-99fc-108d2413edb6.jpg",
            },
        ];
        return (
            <>

                <div className="flex flex-wrap justify-center pb-10 ">
                    {items.map((item, index) => (
                        <div key={index} className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-4">
                            <a href={item.link}>
                                <img className="rounded-t-lg" src={item.imageUrl} alt="" />
                            </a>
                            <div className="p-5">
                                <a href="#">
                                    <h5 className="mb-2 text-2xl text-center font-bold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>


            </>
        )
    }




    return (
        <div className='mb-[70px] mt-[70px]' style={{backgroundImage:"url(/sydney.jpeg)", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>

            <h2 className=' text-center font-bold text-4xl uppercase pt-10'>Intercity Routes</h2>

            <div className='flex justify-center gap-10 mt-8 mb-8'>
                <div className='px-5 py-2 font-bold bg-gray-900 text-white rounded hover:scale-105 active:scale-95 transition-transform cursor-pointer' onClick={() => setCountry('Australia')}>Australia</div>
                <div  className='px-5 py-2 font-bold bg-gray-900 text-white rounded hover:scale-105 active:scale-95 transition-transform cursor-pointer' onClick={() => setCountry('NewZealand')}>NewZealand</div>
            </div>


            {/* <div classNameName='mx-auto w-[200px]'>
                <ProgressBar percent={progress} />
            </div> */}


            {country == "Australia" && (Australia())}
            {country == "NewZealand" && (Newzealand())}


        </div>
    )

}

export default Country;
