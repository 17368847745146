import { FaCarRear } from "react-icons/fa6";

export default function Navbar() {

    function toggle() {
        let x = document.getElementById("menu")
        if (x.style.display === "block") { x.style.display = "none"; }
        else { x.style.display = "block" }
    }

    return (

        <nav className="md:pb-3 pt-3 bg-black text-white">
            <div className="container mx-auto md:flex items-center justify-center md:justify-between max-w-[1200px]">
                <div className="flex items-center md:space-x-4 justify-between">
                    <div className="flex items-center">
                        {/* <FaCarRear size={45} className="mr-3 ml-3 " /> */}
                        <img src="/logo2.png" alt="" className="h-[90px] w-[240px]"/>
                        {/* <span className=" text-lg font-bold tracking-wider text-[21px] md:text-[24px]"><a href="/">BGC</a></span> */}
                    </div>
                    <span onClick={toggle} className="md:hidden text-[22px] font-extrabold mr-4">☰</span>
                </div>

                <div className="md:pb-0 pr-3 md:mt-0 mt-4 md:bg-transparent uppercase">
                    <ul id="menu" className="md:flex md:space-x-10 md:pb-0 pb-4 items-center font-bold  text-center leading-[3] hidden">
                        
                        <li> <a href="/">About</a></li>
                        <li> <a href="/">Contact</a></li>
                        <li> <a href="/">Services</a></li>
                        {/* <li> <a href="/">Contact</a></li> */}
                        {/* <li> <a href="/">Disclaimer</a></li> */}
                        {/* <li> <a href="/">Privacy Policy</a></li> */}
                    </ul>
                </div>
            </div>
        </nav>


    );
}
