import React, { useRef } from 'react';
import { motion } from "framer-motion";

const ImageHover = () => {

    const images = [
        {
            url: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/service-bg1-c3d5e9fe-c491-4d99-80a4-be93bf89f9a6.jpg",
            heading: "CORPORATE TRANSFER SERVICE",
            paragraph: "Our professional transfer solutions provide smooth transportation options to meet our client's demands",
        },
        {
            url: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/service-bg2-74d3f462-5bce-412b-af64-49b5e33565c1.jpg",
            heading: "AIRPORT TRANSFER SERVICE",
            paragraph: "We offer prompt, convenient transportation to and from the airport. In addition to helping you with your luggage, our drivers will greet you at the airport.",
        },
        {
            url: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/service-bg3-bca22721-7bc3-429b-9479-2c0eb8571a29.jpg",
            heading: "SCHOOL SWITCHING SERVICE",
            paragraph: "We offer students dependable, secure transportation. Our chauffeurs are trained to ensure that students get to school or college on time and safely.",
        },
        {
            url: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/service-bg4-2ff15606-206d-4b76-9581-39967de9d4cc.jpg",
            heading: "DAY TOURS AND TRIPS SERVICE",
            paragraph: "Our day outings and tours are ideal for sightseeing or discovering new locales. We can transport you to all the area's top sights and make sure you have a nice experience.",
        },
        {
            url: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/service-bg5-dac3ecff-981d-4d7a-9360-72e65436f108.jpg",
            heading: "MEET AND GREET",
            paragraph: "You can contact us at any time to request our meet and greet service to help with domestic and international flight arrivals and departures. For domestic flights, chauffeurs will be waiting for passengers in the baggage claim area.",
        },
        {
            url: "https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/service-bg6-9efae5c7-a396-45c7-8471-941858758b5d.jpg",
            heading: "SPECIAL EVENTS SERVICE",
            paragraph: "Proms, graduations, birthdays, weddings, and other special occasions are ideal occasions for our special events service. Any size group can use our transportation services. We can arrange for the bride, groom, and wedding party's transportation. For individuals who wish to enjoy a night out , our nightclub or DJ party transfer service is ideal.",
        }
    ];

    const paragraphRefs = useRef([]);

    const handleHover = (index) => {
        paragraphRefs.current[index].style.opacity = '1';
    };

    const handleLeave = (index) => {
        paragraphRefs.current[index].style.opacity = '0';
    };


    return (
        <>
            <div className="flex flex-wrap md:p-8 p-3 ">

                {images.map((item, index) => (
                    <motion.div
                        whileHover={{ scale: 1.03 }}
                        transition={{ duration: 0.5 }}
                        key={index} className="relative w-full sm:w-1/2 md:w-1/3 lg:w-1/3 h-[350px] "
                        onMouseEnter={() => handleHover(index)} onMouseLeave={() => handleLeave(index)} >

                        <img src={item.url} alt={`Image ${index + 1}`}
                            className="w-full h-full  object-cover "
                        />

                        <div className="absolute inset-0 bg-black opacity-60"></div>

                        <div className="absolute top-0 left-0 p-4 transition-opacity duration-300">
                            <h3 className="text-white text-2xl font-bold">{item.heading}</h3>
                            <p ref={(el) => (paragraphRefs.current[index] = el)} className="text-white mt-5 font-bold opacity-0">{item.paragraph}</p>
                        </div>
                    </motion.div>
                ))}
            </div>

        </>
    )
}


export default ImageHover