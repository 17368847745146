import { motion } from "framer-motion";


const Circles = () => {

    const ourExpertise = [
        {
            number: "Luxury",
            year: " airport",
            experince: "  transfer",
        },
        {
            number: "Chauffeur",
            year: "  Service",
        },
        {
            number: "Limousine",
            year: "Service",
        },
        {
            number: "Wedding",
            year: "  transfer",
        },
    ];


    return (
        <div className="pb-[70px]">
            <div className="bg-opacity-50" style={{ backgroundImage: "url(/car.jpg)", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>


                <div className="flex justify-center gap-10 items-center h-screen flex-wrap">
                    {ourExpertise.map((item, index) => (
                        <div className="flex justify-center items-center m-4 " key={index}>
                            <motion.div key={index} animate={{ rotate: 360 }} transition={{ repeat: Infinity, ease: "linear", duration: 20, }} >
                                <img src="/wheel.png" alt="" className="h-[200px] w-[200px]" />
                            </motion.div>

                            <div className="absolute rounded-full h-[140px] w-[140px] text-white bg-gray-900 mx-auto flex justify-center items-center flex-col">
                                <div className="text-xl font-bold">{item.number}</div>

                                <div className="text-sm">{item.year}</div>
                            </div>


                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}


export default Circles