import { IoCall } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

const Footer = () => {
  return (
    <div className="bg-black pt-10 pb-10">
      <div className="max-w-[1300px] mx-auto text-white">
        <div className="md:flex flex-wrap gap-[80px] justify-center p-3 md:text-left text-center">
          <div>
            <img src="/logo2.png" alt="" className="h-[90px] w-[240px]" />
            <div className="font-bold text-[20px] mt-5 mb-3 tracking-wider">
              CONTACT DETAILS
            </div>

            <div className="max-w-[240px] md:mt-6 md:mx-0 mx-auto text-[15px]">
              If you have any questions or need help. feel free contact our
              team.
            </div>

            <div className="flex gap-3 items-center mt-8 md:justify-start justify-center text-[15px]">
              <div>
                <IoCall size={22} />
              </div>
              <div>+61 1300 339 525</div>
            </div>

            <div className="flex gap-3 items-center mt-2 md:justify-start justify-center text-[15px]">
              <div>
                <IoMdMail size={22} />
              </div>
              <div>support@chauffeurcaraustralia.com.au</div>
            </div>
          </div>

          <div className="md:mt-0 mt-10 ">
            <div className="font-bold text-[20px] tracking-wider">LINKS</div>
            <div className="pl-1 text-[15px]">
              <div className="md:mt-6 mt-3">
                <Link to="/">Home</Link>
              </div>
              <div className="mt-2">
                <Link to="/">About</Link>
              </div>
              <div className="mt-2">
                <Link to="/">Services</Link>
              </div>
              <div className="mt-2">
                <Link to="/">Our Fleet</Link>
              </div>
              <div className="mt-2">
                <Link to="/">Blog</Link>
              </div>
              <div className="mt-2">
                <Link to="/">Contact Us</Link>
              </div>
            </div>
          </div>

          <div className=" md:mt-0 mt-10">
            <div className="font-bold text-[20px] tracking-wider">
              LOCATIONS
            </div>
            <div className="pl-1 text-[15px]">
              <div className="md:mt-6 mt-3">Australia Wide</div>
              <div className="mt-2">New Zealand Wide</div>
            </div>
          </div>

          <div className=" md:mt-0 mt-10">
            <div className="font-bold text-[20px] tracking-wider">
              HELPFUL LINKS
            </div>
            <div className="pl-1 text-[15px]">
              <div className="md:mt-6 mt-3">Privacy Policy</div>
              <div className="mt-2">Terms & Condition</div>
              <div className="mt-2 max-w-[200px] md:mx-0 mx-auto">
                Corporate Open Account Autorization PDF Form
              </div>
            </div>
          </div>

          <div className="md:mt-0 mt-10">
            <div className="font-bold text-[20px] tracking-wider">
              FOLLOW US
            </div>
            <div className="pl-1 text-[15px]">
              <div className="md:mt-6 mt-3 flex md:justify-start justify-center">
                <a href="/" className="px-3">
                  {" "}
                  <FaFacebookF size={25} />
                </a>
                <a href="/" className="px-3">
                  {" "}
                  <GrInstagram size={25} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
