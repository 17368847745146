import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MySwiper from "../components/MySwiper";
import React, { useRef } from "react";
import ImageHover from "../components/ImageHover";
import Country from "../components/Country";
import Circles from "../components/Circles";
import BookingFormHome from "../components/HomeBookingForm/Parent/Parentstep";

const Home = () => {
  return (
    <div className="bg-black">
      <Navbar />

      <div
        className="h-[100%]"
        style={{
          backgroundImage: "url(/carcover.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div>
          <BookingFormHome />
        </div>
      </div>

      <section className="relative pt-12 bg-black text-white pb-10">
        <div className="items-center flex flex-wrap">
          <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
            {/* <img alt="..." className="max-w-full rounded-lg shadow-lg" src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80"/> */}
            <video autoPlay muted loop>
              <source src="/phone.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="w-full md:w-5/12 ml-auto mr-auto px-4 md:pt-0 mt-[65px]">
            <div className="md:pr-12 md:px-0 px-4 ">
              {/* <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300 mt-8">
                                <i className="fas fa-rocket text-xl"></i>
                            </div> */}
              <h3 className="text-3xl font-semibold ">A growing company</h3>
              <p className="mt-4 text-lg leading-relaxed">
                The extension comes with three pre-built pages to help you get
                started faster. You can change the text and images and you're
                good to go.
              </p>
              <ul className="list-none mt-6 ">
                <li className="py-2 ">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i className="fas fa-fingerprint"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-blueGray-500">
                        Carefully crafted components
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i className="fab fa-html5"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-blueGray-500">
                        Amazing page examples
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="flex items-center">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                        <i className="far fa-paper-plane"></i>
                      </span>
                    </div>
                    <div>
                      <h4 className="text-blueGray-500">Dynamic components</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-black pt-10">
        <h2 className="font-bold text-[32px] pb-5 pt-5 px-4 text-white md:text-center">
          OUR CHAUFFEUR SERVICES IN AUSTRALIA AND NEW ZEALAND
        </h2>

        <div className="md:flex justify-center gap-10 p-3 text-gray-200 max-w-[1200px] mx-auto">
          <div className="md:w-[50%] md:p-7 p-3 leading-relaxed">
            <p>
              {" "}
              Your premier destination for luxurious chauffeur cars widely
              available across Australia and New Zealand. With a rich legacy in
              the industry spanning several years, CHAUFFEUR CAR AUSTRALIA is
              committed to delivering an experience defined by tranquility and
              extravagance. Our unwavering dedication to excellence has been our
              hallmark, making us the trusted choice for discerning clients
              seeking the epitome of chauffeur service.
            </p>

            <p className="mt-8">
              {" "}
              CHAUFFEUR CAR AUSTRALIA is categorized by its attention to detail
              and high level of customer service, this is what sets it apart
              from another chauffeur company service. It is vital for our
              company to nurture and develop our client relationships to ensure
              repeat business and a sustained growth in profits. In order to
              improve our customer experience and build on relationships that
              encourages repeat business and customer loyalty, it is essential
              for us to log and act on customer preferences and feedback. This
              allows us to tailor the service to individual customer needs, so
              they live up to their expectations every time they get into our
              vehicles.
            </p>

            <p className="mt-8">
              Our company exclusively operates a system of prearranged hires and
              customer service 24/7.
            </p>
          </div>

          <div className="md:w-[50%] md:p-7 p-3">
            <img
              src="https://bgchauffers.s3.ap-southeast-2.amazonaws.com/B-G-C/our-chauffeur-services-a59b9ee0-521a-4c1b-9c8e-c14cb751fbfe.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <Circles />
      <ImageHover />
      <MySwiper />
      <Country />
      <Footer />
    </div>
  );
};

export default Home;
